import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Chip,
  Snackbar,
  Alert,
  Tooltip,
  CircularProgress,
  InputBase,
} from "@mui/material";
import { Add as AddIcon, Search as SearchIcon, Edit as EditIcon } from "@mui/icons-material";
import { Terminal } from 'lucide-react';
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";
import axios from "axios";
import AddStudioModal from "./AddStudioModal";
import { useNavigate } from "react-router-dom";
import AppHeader from "./AppHeader";

const DataSourcesPage = () => {
  const [studios, setStudios] = useState([]);
  const [filteredStudios, setFilteredStudios] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [studioModalOpen, setOpenStudioModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [isRefreshing, setIsRefreshing] = useState(false);

  async function getUser() {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/user`, {
        headers: headers,
      });

      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAvailableStudios() {
    setIsRefreshing(true);
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/studio/seeds`, {
        headers: headers,
      });

      if (response.data) {
        const sortedStudios = response.data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setStudios(sortedStudios);
        setFilteredStudios(sortedStudios);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  }

  useEffect(() => {
    getUser();
    getAvailableStudios();
  }, []);

  useEffect(() => {
    const filtered = studios.filter(studio => 
      studio.display_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredStudios(filtered);
  }, [searchQuery, studios]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'READY':
        return 'rgba(46, 78, 65, 0.6)';
      case 'BOOTING':
      case 'INDEXING':
        return 'rgba(115, 92, 45, 0.6)';
      case 'FAILED':
        return 'rgba(115, 53, 53, 0.6)';
      default:
        return 'rgba(56, 56, 56, 0.6)';
    }
  };

  const addStudioToList = (newStudio) => {
    setStudios(prevStudios => [newStudio, ...prevStudios]);
    setFilteredStudios(prevFiltered => [newStudio, ...prevFiltered]);
  };

  return (
    <>
      <AppHeader user={user} />
      <Box sx={{ 
        minHeight: '100vh',
        pt: 8,
        px: 4,
        backgroundColor: '#121212',
      }}>
        <Box sx={{
          maxWidth: '1200px',
          margin: '0 auto',
        }}>
          {/* Header Section */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 6
          }}>
            <Box>
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 600, 
                  color: '#fff',
                  mb: 1
                }}
              >
                Studios
              </Typography>
              <Typography sx={{ color: '#888', fontSize: '0.875rem' }}>
                Create and manage your development environments
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#1a1a1a',
                  borderRadius: 1,
                  border: '1px solid #2a2a2a',
                  px: 2,
                  py: 1,
                  '&:focus-within': {
                    borderColor: '#333',
                  },
                }}
              >
                <SearchIcon sx={{ color: '#666', mr: 1 }} />
                <InputBase
                  placeholder="Search studios..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{
                    color: '#fff',
                    fontSize: '0.875rem',
                    width: '200px',
                    '& input::placeholder': {
                      color: '#666',
                      opacity: 1,
                    },
                  }}
                />
              </Box>
              <Tooltip title="Create New Studio" placement="left">
                <IconButton
                  onClick={() => setOpenStudioModal(true)}
                  sx={{
                    border: '1px solid #333',
                    borderRadius: 1,
                    p: 1,
                    '&:hover': {
                      backgroundColor: '#252525',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#fff'
                    }
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          {/* Studios Grid */}
          {isLoading ? (
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px'
            }}>
              <CircularProgress size={32} sx={{ color: '#fff' }} />
            </Box>
          ) : (
            <Box sx={{ 
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
              gap: 3,
              pb: 6,
              opacity: isRefreshing ? 0.5 : 1,
              pointerEvents: isRefreshing ? 'none' : 'auto',
              position: 'relative'
            }}>
              {isRefreshing && (
                <CircularProgress 
                  size={32} 
                  sx={{ 
                    color: '#fff',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1
                  }} 
                />
              )}
              {filteredStudios.map((studio) => (
                <Box
                  key={studio.id}
                  onClick={() => studio.status === "READY" && navigate(`/studio/${studio.id}`)}
                  sx={{
                    backgroundColor: '#1a1a1a',
                    borderRadius: 1,
                    cursor: studio.status === "READY" ? 'pointer' : 'default',
                    transition: 'all 0.2s ease',
                    border: '1px solid #2a2a2a',
                    '&:hover': studio.status === "READY" ? {
                      backgroundColor: '#171717',
                      borderColor: '#333',
                      '& .edit-chip': {
                        opacity: 1,
                      }
                    } : {},
                  }}
                >
                  <Box sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography sx={{ 
                          fontSize: '1rem',
                          fontWeight: 500,
                          color: '#fff'
                        }}>
                          {studio.display_name}
                        </Typography>
                        <Chip
                          icon={<EditIcon sx={{ fontSize: '14px !important' }} />}
                          label="Edit"
                          size="small"
                          className="edit-chip"
                          sx={{
                            backgroundColor: '#252525',
                            color: '#888',
                            fontSize: '0.675rem',
                            height: '20px',
                            opacity: 0,
                            transition: 'opacity 0.2s ease',
                            '& .MuiChip-icon': {
                              color: '#888',
                            }
                          }}
                        />
                      </Box>
                      <Chip
                        label={studio.status}
                        size="small"
                        sx={{
                          backgroundColor: getStatusColor(studio.status),
                          color: '#fff',
                          fontWeight: 500,
                          fontSize: '0.675rem',
                          height: '24px',
                          border: 'none',
                        }}
                      />
                    </Box>
                    
                    {studio.tags && studio.tags.length > 0 && (
                      <Box sx={{
                        display: 'flex',
                        gap: 1,
                        flexWrap: 'wrap'
                      }}>
                        {studio.tags.map((tag, index) => (
                          <Chip
                            key={index}
                            label={tag}
                            size="small"
                            sx={{
                              backgroundColor: '#252525',
                              color: '#888',
                              fontSize: '0.675rem',
                              height: '20px',
                            }}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}

              {filteredStudios.length === 0 && (
                <Box sx={{
                  gridColumn: '1 / -1',
                  p: 6,
                  textAlign: 'center',
                  border: '1px dashed #333',
                  borderRadius: 1,
                  backgroundColor: '#1a1a1a',
                }}>
                  <Terminal size={32} style={{ color: '#444', marginBottom: '16px' }} />
                  <Typography sx={{ 
                    color: '#888', 
                    fontSize: '0.875rem',
                    maxWidth: '400px',
                    margin: '0 auto'
                  }}>
                    {studios.length === 0 
                      ? "No studios available. Create your first studio to start building your development environment."
                      : "No studios found matching your search."}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="success"
            sx={{
              backgroundColor: '#252525',
              color: '#fff',
              '& .MuiAlert-icon': {
                color: '#fff'
              }
            }}
          >
            Studio created successfully. Please wait a few minutes for it to be ready...
          </Alert>
        </Snackbar>

        <AddStudioModal 
          open={studioModalOpen} 
          onClose={() => setOpenStudioModal(false)} 
          onSuccess={(newStudio) => {
            setSnackbarOpen(true);
            addStudioToList(newStudio);
          }} 
        />
      </Box>
    </>
  );
};

export default DataSourcesPage;
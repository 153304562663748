import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Terminal, ChevronUp } from 'lucide-react';

const TerminalLookCard = ({ 
  output,
  isCollapsed: externalCollapsed,
  onToggleCollapse,
  defaultCollapsed = false,
  animate = true
}) => {
  const [isRevealing, setIsRevealing] = useState(animate);
  const [showOutput, setShowOutput] = useState(!animate);
  const [internalCollapsed, setInternalCollapsed] = useState(defaultCollapsed);

  const isCollapsed = externalCollapsed !== undefined && !internalCollapsed ? externalCollapsed : internalCollapsed;

  useEffect(() => {
    if (!animate) {
      setIsRevealing(false);
      setShowOutput(true);
      return;
    }

    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
      setTimeout(() => setShowOutput(true), 300);
    }, 1000);

    return () => clearTimeout(revealTimer);
  }, [animate]);

  const handleToggleCollapse = () => {
    if (onToggleCollapse) {
      onToggleCollapse(!isCollapsed);
    } else {
      setInternalCollapsed(!internalCollapsed);
    }
  };

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: isRevealing ? '200px' : 'auto',
          maxHeight: isRevealing ? '200px' : (isCollapsed ? '60px' : 500),
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          overflowY: 'hidden',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: animate ? 'all 0.3s ease-out' : 'none',
          animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          },
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            opacity: isRevealing ? 0 : 1,
            transform: isRevealing ? 'translateY(10px)' : 'translateY(0)',
            transition: animate ? 'all 0.3s ease-out' : 'none',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Terminal 
              size={20}
              style={{ 
                marginRight: '12px',
                color: '#337a3d'
              }}
            />
            <Typography
              component="span"
              sx={{
                fontSize: '0.875rem',
              }}
            >
              Terminal Output
            </Typography>
          </Box>

          {!isRevealing && (
            <IconButton
              onClick={handleToggleCollapse}
              size="small"
              sx={{
                color: '#fff',
                transition: 'transform 0.2s ease',
                transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            >
              <ChevronUp size={20} />
            </IconButton>
          )}
        </Box>

        {showOutput && (
          <Box
            sx={{
              mt: 2,
              opacity: showOutput ? 1 : 0,
              transition: animate ? 'all 0.3s ease-in' : 'none',
              maxHeight: isCollapsed ? 0 : 420,
              overflow: isCollapsed ? 'hidden' : 'auto',
              visibility: isCollapsed ? 'hidden' : 'visible',
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#0f0f0f',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#2a2a2a',
                borderRadius: '4px',
              },
              scrollbarWidth: 'thin',
            }}
          >
            <Box
              sx={{
                p: 2,
                borderRadius: 1,
                fontFamily: 'monospace',
                fontSize: '0.875rem',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
              }}
            >
              {output.split('\n').map((line, index) => (
                <div
                  key={index}
                  style={{
                    animation: animate ? `fadeIn 0.2s ease-in forwards` : 'none',
                    animationDelay: animate ? `${index * 0.05}s` : '0s',
                    opacity: animate ? 0 : 1,
                  }}
                >
                  {line}
                </div>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TerminalLookCard; 
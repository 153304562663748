import React, { memo } from 'react';
import MessageCard from './Cards/MessageCard';
import FileCreationCard from './Cards/FileCreationCard';
import FileOpenCard from './Cards/FileOpenCard';
import EditCodeCard from './Cards/EditCodeCard';
import UnixCommandCard from './Cards/UnixCommandCard';
import BrowserClickCard from './Cards/BrowserClickCard';
import TypeInputCard from './Cards/TypeInputCard';
import GoToUrlCard from './Cards/GoToUrlCard';
import BrowserBackCard from './Cards/BrowserBackCard';
import QuestionCard from './Cards/QuestionCard';
import CodeSearchCard from './Cards/CodeSearchCard';
import BrowserLookCard from './Cards/BrowserLookCard';
import TerminalLookCard from './Cards/TerminalLookCard';
import BrowserRecordCard from './Cards/BrowserRecordCard';
import BrowserStopRecordCard from './Cards/BrowserStopRecordCard';
import StepCompletionCard from './Cards/StepCompletionCard';
import TaskCompletionCard from './Cards/TaskCompletionCard';
import CreatePRCard from './Cards/CreatePRCard';
import { Box, Divider } from '@mui/material';

const MemoizedMessageCard = memo(({ message, index, totalMessages, onToggleCollapse }) => {
  const isLast = index === totalMessages - 1;
  const showDivider = index !== 0;
  const isCollapsed = totalMessages - index > 3;

  const commonProps = {
    isCollapsed,
    animate: isLast,
    onToggleCollapse: () => onToggleCollapse(message.id),
  };

  if (message.content?.type === 'create_file' && message.content.data) {
    return (
      <Box sx={{ mb: 3 }}>
        <FileCreationCard
          filePath={message.content.data.file_path}
          content={message.content.data.content}
          {...commonProps}
        />
      </Box>
    );
  }

  if (message.content?.type === 'get_file_content' && message.content.data) {
    return (
      <Box sx={{ mb: 3 }}>
        <FileOpenCard
          filePath={message.content.data.file_path}
          content={message.content.data.content}
          {...commonProps}
        />
      </Box>
    );
  }

  if (message.content?.type === 'file_edit' && message.content.data) {
    return (
      <Box sx={{ mb: 3 }}>
        <EditCodeCard
          filePath={message.content.data.file_path}
          diff={message.content.data.changes}
          {...commonProps}
        />
      </Box>
    );
  }

  if (message.content?.type === 'unix_command' && message.content.data) {
    return (
      <Box sx={{ mb: 3 }}>
        <UnixCommandCard
          command={message.content.data.command}
          output={message.content.data.output}
          {...commonProps}
        />
      </Box>
    );
  }

  if (message.content?.type === 'click_element') {
    return (
      <Box sx={{ mb: 3 }}>
        <BrowserClickCard animate={isLast} />
      </Box>
    );
  }

  if (message.content?.type === 'type_input' && message.content.data) {
    return (
      <Box sx={{ mb: 3 }}>
        <TypeInputCard text={message.content.data.text} animate={isLast} />
      </Box>
    );
  }

  if (message.content?.type === 'go_to_url' && message.content.data) {
    return (
      <Box sx={{ mb: 3 }}>
        <GoToUrlCard url={message.content.data.url} animate={isLast} />
      </Box>
    );
  }

  if (message.content?.type === 'browser_back') {
    return (
      <Box sx={{ mb: 3 }}>
        <BrowserBackCard animate={isLast} />
      </Box>
    );
  }

  if (message.content?.type === 'web_search' && message.content.data) {
    return (
      <Box sx={{ mb: 3 }}>
        <QuestionCard
          question={message.content.data.question}
          answer={message.content.data.answer}
          {...commonProps}
        />
      </Box>
    );
  }

  if (message.content?.type === 'code_search' && message.content.data) {
    return (
      <Box sx={{ mb: 3 }}>
        <CodeSearchCard
          query={message.content.data.query}
          results={message.content.data.results}
          {...commonProps}
        />
      </Box>
    );
  }

  if (message.content?.type === 'browser_look') {
    return (
      <Box sx={{ mb: 3 }}>
        <BrowserLookCard animate={isLast} />
      </Box>
    );
  }

  if (message.content?.type === 'look_on_process' && message.content.data) {
    return (
      <Box sx={{ mb: 3 }}>
        <TerminalLookCard
          output={message.content.data.output}
          {...commonProps}
        />
      </Box>
    );
  }

  if (message.content?.type === 'start_web_recording') {
    return (
      <Box sx={{ mb: 3 }}>
        <BrowserRecordCard />
      </Box>
    );
  }

  if (message.content?.type === 'stop_web_recording') {
    return (
      <Box sx={{ mb: 3 }}>
        <BrowserStopRecordCard animate={isLast} />
      </Box>
    );
  }

  if (message.content?.type === 'step_done') {
    return (
      <Box sx={{ mb: 3 }}>
        <StepCompletionCard
          stepDescription={message.content?.data?.step_description || ''}
          {...commonProps}
        />
      </Box>
    );
  }

  if (message.content?.type === 'task_done') {
    return (
      <Box sx={{ mb: 3 }}>
        <TaskCompletionCard animate={isLast} />
      </Box>
    );
  }

  if (message.content?.type === 'chat_message' && message.content?.data) {
    return (
      <>
        {showDivider && <Divider sx={{ my: 3 }} />}
        <MessageCard message={message} isLast={isLast} />
      </>
    );
  }

  if (message.content?.type === 'create_pr' && message.content.data) {
    return (
      <Box sx={{ mb: 3 }}>
        <CreatePRCard
          title={message.content.data.title}
          body={message.content.data.body}
          prLink={message.content.data.pr_link}
          animate={isLast}
        />
      </Box>
    );
  }

  return null;
}, (prevProps, nextProps) => {
  // Custom comparison function
  return (
    prevProps.message.id === nextProps.message.id &&
    prevProps.index === nextProps.index &&
    prevProps.totalMessages === nextProps.totalMessages
  );
});

export default MemoizedMessageCard; 
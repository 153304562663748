import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Command, CornerDownLeft } from 'lucide-react';

const ChatInput = ({
  onSendMessage,
  isLoading,
  onStopGeneration,
  message,
  setMessage,
  mode = 'chat',
  status,
}) => {
  const [internalMessage, setInternalMessage] = useState('');
  const [statusState, setStatusState] = useState({ text: '', visible: false });
  const messageToUse = message !== undefined ? message : internalMessage;
  const setMessageToUse = setMessage !== undefined ? setMessage : setInternalMessage;
  const [height, setHeight] = useState(100); // Initial height
  const dragRef = useRef(null);
  const startHeightRef = useRef(height);
  const startYRef = useRef(0);

  useEffect(() => {
    if (status) {
      setStatusState({ text: status, visible: true });
    } else {
      setStatusState(prev => ({ ...prev, visible: false }));
    }
  }, [status]);

  const handleDragStart = (e) => {
    e.preventDefault();
    startYRef.current = e.clientY;
    startHeightRef.current = height;

    document.addEventListener('mousemove', handleDragMove);
    document.addEventListener('mouseup', handleDragEnd);
  };

  const handleDragMove = (e) => {
    const deltaY = startYRef.current - e.clientY;
    if (mode === 'home') {
      // For home mode, invert the direction
      const newHeight = Math.max(100, startHeightRef.current - deltaY);
      setHeight(newHeight);
    } else {
      // For chat mode, keep original behavior
      const newHeight = Math.max(100, startHeightRef.current + deltaY);
      setHeight(newHeight);
    }
  };

  const handleDragEnd = () => {
    document.removeEventListener('mousemove', handleDragMove);
    document.removeEventListener('mouseup', handleDragEnd);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (messageToUse.trim()) {
      onSendMessage(messageToUse);
      setMessageToUse('');
      setStatusState({ text: "Fume is waking up", visible: true });
    }
  };

  const handleChange = (e) => {
    setMessageToUse(e.target.value);
  };

  const handleKeyDown = (e) => {
    // Prevent regular Enter from submitting
    if (e.key === 'Enter' && !e.metaKey && !e.ctrlKey) {
      e.stopPropagation();
      return;
    }
    
    // Check for Cmd+Enter (Mac) or Ctrl+Enter (Windows)
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const DragHandle = () => (
    <Box
      ref={dragRef}
      onMouseDown={handleDragStart}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        cursor: 'row-resize',
        py: 0.5,
        borderTop: mode === 'home' ? '1px solid #333' : 'none',
        borderBottom: mode === 'chat' ? '1px solid #333' : 'none',
        userSelect: 'none',
        '&:hover': {
          backgroundColor: '#252525',
        },
      }}
    >
      <DragHandleIcon sx={{ color: '#666', fontSize: 20 }} />
    </Box>
  );

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        p: 2,
        backgroundColor: 'transparent',
        width: '100%',
        position: 'relative',
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: -40,
          left: '50%',
          transform: 'translateX(-50%)',
          color: '#fff',
          fontSize: '0.875rem',
          opacity: statusState.visible ? 1 : 0,
          visibility: statusState.visible ? 'visible' : 'hidden',
          transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
          backgroundColor: '#252525',
          border: '1px solid #444',
          borderRadius: '9999px',
          padding: '4px 12px',
          whiteSpace: 'nowrap',
          zIndex: 11,
          display: 'flex',
          alignItems: 'center',
          gap: '2px',
        }}
      >
        {statusState.text}
        <Box component="span" sx={{ display: 'inline-flex', gap: '2px', ml: '4px' }}>
          {[0, 1, 2].map((i) => (
            <Box
              key={i}
              component="span"
              sx={{
                width: '4px',
                height: '4px',
                backgroundColor: '#fff',
                borderRadius: '50%',
                display: 'inline-block',
                animation: 'bounce 1.4s ease-in-out infinite',
                animationDelay: `${i * 0.2}s`,
                '@keyframes bounce': {
                  '0%, 100%': {
                    transform: 'translateY(0)',
                  },
                  '50%': {
                    transform: 'translateY(-4px)',
                  },
                },
              }}
            />
          ))}
        </Box>
      </Box>

      <Box 
        sx={{ 
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#121212',
          border: '1px solid #333',
          borderRadius: 1,
          height: `${height}px`,
          width: '100%', // Ensure full width
        }}
      >
        {mode === 'chat' && <DragHandle />}

        <Box sx={{ 
          display: 'flex', 
          gap: 1,
          p: 1, // Consistent padding
          flexGrow: 1,
          overflow: 'hidden',
          backgroundColor: '#121212',
          alignItems: 'flex-start', // Align items to top
        }}>
          <TextField
            fullWidth
            value={messageToUse}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={mode === 'home' ? 'Give Fume something to work on...' : 'Send a message... (Will not interrupt Fume)'}
            multiline
            minRows={1}
            maxRows={20}
            variant="standard"
            sx={{
              flexGrow: 1,
              height: '100%',
              '& .MuiInputBase-root': {
                height: '100%',
                backgroundColor: 'transparent',
              },
              '& .MuiInput-root': {
                height: '100%',
                '&:before, &:after': {
                  display: 'none',
                },
              },
              '& .MuiInputBase-input': {
                height: '100% !important',
                color: '#fff',
                backgroundColor: '#121212',
                overflow: 'auto !important',
                padding: '0 8px', // Add consistent padding
                '&::placeholder': {
                  color: '#666',
                  opacity: 1,
                },
              },
            }}
          />
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            pt: 0.5, // Reduced top padding
          }}>
            <IconButton 
              type={isLoading ? "button" : "submit"} 
              onClick={isLoading ? onStopGeneration : undefined}
              color="primary"
              sx={{
                backgroundColor: '#121212',
                border: '1px solid #333',
                borderRadius: 1,
                p: 1, // Consistent padding
                height: '36px', // Fixed height
                minWidth: '80px', // Fixed minimum width
                '&:hover': {
                  backgroundColor: '#252525',
                },
                fontSize: '0.875rem',
                textTransform: 'none',
                color: '#fff',
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isLoading ? <StopIcon /> : (
                <>
                  <Box sx={{ 
                    display: 'flex', 
                    gap: 0.5, 
                    alignItems: 'center',
                    color: '#666',
                  }}>
                    <Command size={14} />
                    <CornerDownLeft size={14} />
                  </Box>
                  {mode === 'home' ? 'Start' : 'Send'}
                </>
              )}
            </IconButton>
          </Box>
        </Box>

        {mode === 'home' && <DragHandle />}
      </Box>
    </Box>
  );
};

export default ChatInput;